import axios from 'axios';
import store from '../redux/store';
import { gtagGentooResponse, gtagRecommendProduct } from './gtag';

export const getChatbotReply = async function (userMessage, roomId, setIsGeneratingAnswer, requestURL, clientId, apiKey, itemId = 'general', isMobileDevice) {
    const state = store.getState();
    const udid = state.auth.udid;
    
    try {
        setIsGeneratingAnswer(true);
        const response = await axios.post(requestURL, {
            clientId: clientId,
            requestMessage: userMessage,
            userId: roomId,
            channelId: isMobileDevice ? 'mobile' : 'web',
            itemId: itemId,
        }, {
            headers: {
                'x-api-key': apiKey,
            }
        });
        setIsGeneratingAnswer(false);
        const data = response.data.data;
        if (data && data.text) {
            gtagGentooResponse(data.text, roomId, clientId, data.intent);
            if (data.intent === '제공') gtagRecommendProduct(data.text, roomId, clientId);
            return data;
        } else {
            throw new Error('Invalid server response');
        }
    } catch (error) {
        setIsGeneratingAnswer(false);
        console.error(`Error while calling chatbot API: ${error.message}`);
        return {text: '네트워크가 불안정합니다. 다시 시도해 주세요.'};
    }
};

export const resetQueryCondition = async (roomId, requestURL, clientId, apiKey = undefined) => {
    const state = store.getState();
    const userId = roomId;
    try {
        const response = await axios.post(requestURL, {
            userId: userId,
            clientId: clientId,
        }, {
            headers: {
                'x-api-key': apiKey,
            }
        });
    } catch (error) {
        console.error(`Error while calling chatbot API: ${error.message}`);
    }
};

export const requestSignup = async (email, password='', company = '', job = '') => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_SIGNUP_URL,
            {
                email: email,
                password: password,
                company: company,
                job: job,
            }, {
                headers: {}
            }
        )
        return true
    } catch (error) {
        console.error(`Error while calling signup API: ${error.response.data.message}`);
    }
}

export const requestLogin = async (email, password) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_DEMO_LOGIN_URL,
            {
                email: email,
                password: password,
            }, {
                headers: {}
            }
        )
        return true
    } catch (error) {
        console.error(`Error while calling login API: ${error.response.data.message}`);
    }
}

export const fetchChatHistory = async (requestURL, userId, clientId, channelId = '', apiKey, setMessages) => {
    try {
        const response = await axios.post(
            requestURL,
            {
                userId: userId,
                clientId: clientId,
                channelId: channelId,
            }, {
                headers: {
                    'x-api-key': apiKey
                }
            }
        )
        if (response.data.chat) {
            const formattedChatHistory = response.data.chat.map((message, index) => {
                if (!message.content[0].includes('새로운 대화하기') && !message.content[0].includes('새로운 대화를 시작했어요')) {
                    if (message.product) {
                        return {
                            userId: 'Accio',
                            message: message.content,
                            example: message?.example,
                            product: message.product,
                            dialId: message.dialogueId,
                            intent: message.intent,
                        }
                    } else {
                        return {
                            userId: message.role === 'user' ? userId : 'Accio',
                            message: message.content,
                            example: message?.example,
                            dialId: message.dialogueId,
                            intent: message.intent,
                            time: new Date().toString(),
                        }
                    };
                };
                return null;
            });
            setMessages(formattedChatHistory.filter(Boolean));
            return formattedChatHistory.filter(Boolean).length > 0;
        } else {
            return false;
        }
    } catch (error) {
        console.error('fetchChatHistory err: ', error);
    }
}

export const fetchStartingPhrase = async (userId, requestURL) => {
    try {
        const response = await axios.get(
            requestURL, {
                params: {
                    randomId: userId,
                }
            }
        )
        // const startingPhrase = JSON.parse(response.data.body).startingPhrase;
        return response.data.body.startingPhrase;
    } catch (error) {
        console.error(`Error while calling fetchStartingPhrase API: ${error.response.data.message}`)
    }
}

export const initStartingPhrase = async (userId, requestURL) => {
    try {
        const response = await axios.post(
            requestURL,
            {
                randomId: userId,
            }, {
                headers: {}
            }
        )
        // const startingPhrase = JSON.parse(response.data.body).startingPhrase;
        return response.data.body;
    } catch (error) {
        console.error(`Error while calling fetchStartingPhrase API: ${error.response.data.message}`)
    }
}

export const sendFeedback = async (clientId, channelId, userId, requestMessage, apiKey) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_CUST_FEEDBACK_CKAT_URL,
            {
                clientId: clientId,
                channelId: channelId,
                userId: userId,
                requestMessage,
            }, {
                headers: {
                    'x-api-key': apiKey
                }
            }
        )
        return '피드백이 접수되었어요!';
    } catch (error) {
        console.error(`Error while calling sendFeedback API: ${error.response}`)
    }
}

export const fetchLastChatStatus = async (userId, clientId, apiKey) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_CUST_ISFINAL_CKAT_URL,
            {
                userId: userId,
                clientId: clientId,
            }, {
                headers: {
                    'x-api-key': apiKey,
                }
            }
        )
        return response.data.isFinal;
    } catch (error) {
        console.error(`Error while calling fetchLastChatStatus API: ${error.response.data.message}`)
    }
}

export const fetchExample = async (userId, clientId, itemId = 'general') => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_CUST_EXAM_DLST_URL,
            {
                userId: userId,
                clientId: clientId,
                itemId: itemId,
            }, {
                headers: {}
            }
        )
        return response.data.example;
    } catch (error) {
        console.error(`Error while calling fetchExample API: ${error}`);
    }
}

export const fetchRanking = async (requestURL, userId) => {
    try {
        const response = await axios.get(
            requestURL, 
            {
                params: {
                    userId: userId,
                },
                headers: {}
            }
        )
        return response.data.top_keywords
    } catch (error) {
        console.error(`Error while calling fetchRanking API: ${error}`)
    }
}

export const fetchFloatingComment = async (requestURL) => {
    try {
        const response = await axios.get(
            requestURL,
            {
                params: {
                    itemId: 3873, // 틸링 싱글몰트 위스키
                },
                headers: {}
            }
        )
    } catch (error) {
        console.error(`Error while calling fetchFloatingComment API: ${error}`);
    }
}

export const fetchFloatingProduct = async (requestURL, payload) => {
    console.log('payload', payload);
    try {
        const response = await axios.post(
            requestURL, 
            {
                itemId: payload.itemId,
                userId: payload.userId,
                target: payload.type,
                channelId: JSON.parse(payload.isMobile) ? 'Mobile' : 'Web',
            }
        )

        return response.data
    } catch (error) {
        console.error(`Error while calling fetchFloatingProduct API: ${error}`)
    }
}

export const logEvent = async (requestURL, apiKey, event, userId, itemId, clientId, isMobile) => {
    const channelId = isMobile ? 'Mobile' : 'Web'; 
    try {
        const response = await axios.post(
            requestURL,
            {
                event_category: event,
                visitorId: userId,
                itemId: String(itemId),
                clientId: clientId,
                channelId: channelId,
            }, {
                headers: {
                    'x-api-key': apiKey,
                }
            }
        )
        // const startingPhrase = JSON.parse(response.data.body).startingPhrase;
        return response.data.body;
    } catch (error) {
        console.error(`Error while calling fetchStartingPhrase API: ${error.response.data.message}`)
    }
}